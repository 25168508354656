<template>
  <dash-page-new
      :root="$t('Dashboard')"
      :root-location="businessDashLink('')"
      :subtitle="$t('AllNewslettters')"
      navigation-route="newsletter"

      :title="$store.state.newsletter.pageData.title || $t('Newsletter')"
      :icon="$store.state.newsletter.pageData.icon || 'mdi-email'"
      :filters="$store.state.newsletter.pageData.filters || {}"
      :header-route="$store.state.newsletter.pageData.headerRoute || null"
      :filters-input="$store.state.newsletter.filtersData || []"
      :side-navigation="$store.state.newsletter.navigationSelect"
      @filter="$store.state.newsletter.filtersData = $event"

      no-side >

    <template #header_action>

      <v-btn v-if="$store.state.newsletter.pageData.headerAction"
             @click="$store.state.newsletter.pageData.headerAction()"
             :color="wsATTENTION"
             class="noCaps"
             dark
            >
        {{ $store.state.newsletter.pageData.headerActionText }}
      </v-btn>
    </template>


    <router-view></router-view>





  </dash-page-new>
</template>

<script>

export default {
  name: "BusinessNewsletter",
  data() {
    return {

    }
  },
  computed : {
    navigationItems() {
      return [
        // { text : this.$t('Campaign')      , value : 'crm'           , path : 'crm'       , icon : "mdi-card-account-phone-outline" },
        { text : this.$t('Email')     , value : 'email'     , path : 'email'   , icon : "mdi-credit-card" },
        { text : this.$t('Telegram')  , value : 'telegram'  , path : 'telegram'  , icon : "mdi-send" },
        // { text : this.$t('System')    , value : 'system'    , path : 'system'   , icon : "mdi-server" },
      ]
    }
  },
  mounted() {
    this.$store.state.newsletter.navigationSelect = this.navigationItems
  },
}
</script>

<style scoped>

</style>